const pairs = [
  {
    first:{name:"Your smart specialist for high-quality firing results", description:"Modern. Intuitive. Smart. With the objective to meet the requirements of your dental laboratory. Proven technologies, such as the infrared technology, combined with new features - that is what makes the new Programat ceramic furnace your reliable specialist.", img:require("../images/ivoclar/image1.jpg")},
    second:{name:"High process reliability", description:"High process reliability due to infrared technology (IRT) : The integrated thermographic camera is sensitive to infrared radiation and measures the temperature directly at the surface of the objects being fired. The furnace head and the heater are controlled with the infrared camera to ensure that the predefined pre-drying temperature is consistently maintained on the objects in the furnace.", img:require("../images/ivoclar/image2.jpg")},
  },
  {
    first:{name:"Intuitive operation", description:"New membrane-sealed keypad with proven colour touchscreen display. New user interface featuring new symbols and icons. Efficient and reliable performance due to software optimizations", img:require("../images/ivoclar/image3.jpg")},
    second:{name:"Delivers more than meets the eye.", description:"Now with IoT functionality! Stay informed about your furnace. Receive a monthly usage report for your device free of charge via e-mail.", img:require("../images/ivoclar/image4.jpg")},
  },
]

export default function Ivolar() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">The new Programat ceramic furnaces</h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            P710 G2 & P510 G2 & P310 G2</p>
        </div>
        {pairs.map(pair => (
          <div class="mt-32">
            <div class="bg-white">
            <div class="relative isolate py-4 px-4">
              <div class="mx-auto max-w-7xl px-6 lg:flex lg:items-center lg:gap-x-10 lg:px-8">
                
                <div class="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">
                  <h1 class="mt-10 max-w-lg text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">{pair.first.name}</h1>
                  <p class="mt-6 text-lg leading-8 text-gray-600">{pair.first.description}</p>
                </div>

                <div class="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow">
                  <img src={pair.first.img} alt="" 
                  className="aspect-[16/9] w-full  max-w-2xl rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"/>
                </div>
              </div>
            </div>
            </div>
            <div class="bg-gray-50 mt-32">
              <div class="relative isolate py-4 px-4">
                <div class="mx-auto max-w-7xl px-6 lg:flex lg:items-center lg:gap-x-10 lg:px-8">
                  
                  <div class="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">
                    <h1 class="mt-10 max-w-lg text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">{pair.second.name}</h1>
                    <p class="mt-6 text-lg leading-8 text-gray-600">{pair.second.description}</p>
                  </div>

                  <div class="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow">
                    <img src={pair.second.img} alt="" 
                    className="aspect-[16/9] w-full max-w-2xl rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}

      </div>
    </div>
  )
}
