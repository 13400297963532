import Hero from '../components/hero.js';
import Products from '../components/products.js';
import Contact from '../components/contact.js';
import Display from '../components/display.js';

export default function Home() {
  return (
    <div>
        <Hero/>
        <Products/>
        <Display/>
        <Contact/>
    </div>
  )
}