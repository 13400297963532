/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-redundant-roles */
const posts = [
  {
    id: 1,
    title: 'Medit i700 wireless',
    imageUrl:
      require("../images/medit/mediti700wireless.jpg"),
    description: "No More Wires. No wire, no hassle."
  },
  {
    id: 1,
    title: 'Medit i700',
    imageUrl:
      require("../images/medit/mediti700.jpg"),
    description: "Unleash your clinic's full potential."
  },
  {
    id: 3,
    title: 'Medit i600',
    imageUrl:
      require("../images/medit/mediti600.jpg"),
    description: "The Brain of the i700 with a Splash of Color"
  },
  {
    id: 4,
    title: 'Medit i500',
    imageUrl:
      require("../images/medit/mediti500.jpg"),
    description: "Value, Efficiency, and Productivity​"
  },
  {
    id: 5,
    title: 'Medit T710',
    imageUrl:
      require("../images/medit/Dental Lab IT710 I Rebranding.jpg"),
    description: "Speed Up Your Workflow​"
  },
  {
    id: 6,
    title: 'Medit T510',
    imageUrl:
      require("../images/medit/Dental Lab IT510 I Rebranding.jpg"),
    description: "Previous Models​"
  },
  {
    id: 7,
    title: 'Medit T310',
    imageUrl:
      require("../images/medit/Dental Lab IT310 I Rebranding.jpg"),
    description: "Previous Models​"
  },


]

const pairs = [
  {
    first: { name: "Medit T710", description: "2 steps", img: require("../images/medit/img1.jpg") },
    second: { name: "Medit T500", description: "3 steps", img: require("../images/medit/img2.jpg") },
  },
  {
    first: { name: "Conventional", description: "5 steps", img: require("../images/medit/img3.jpg") },
    second: { name: "Do More with Less Effort", description: "We’ve always prioritized simplicity when developing our solutions. Because we want to ease your work.", img: require("../images/medit/img4.jpg") },
  },
  {
    first: { name: "Convenience", description: "Full-size articulator scanning", img: require("../images/medit/img5.jpg") },
    second: { name: "Versatility", description: "Integration with Medit i700 wireless Intraoral Scanner", img: require("../images/medit/img6.jpg") },
  },
];

export default function Medits() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">MEDIT</h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            Start Your Magical Journey into Digital Dentistry with the MEDIT Family.</p>
        </div>
        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {posts.map((post) => (
            <article key={post.id} className="flex flex-col items-start justify-between">
              <div className="relative w-full">
                <img
                  src={post.imageUrl}
                  alt=""
                  className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                />
                <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
              </div>
              <div className="max-w-xl">
                <div className="mt-8 flex items-center gap-x-4 text-xs">

                </div>
                <div className="group relative">
                  <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                    <a href={post.href}>
                      <span className="absolute inset-0" />
                      {post.title}
                    </a>
                  </h3>
                  <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">{post.description}</p>
                </div>
              </div>
            </article>
          ))}
        </div>

        <div className="mt-32 mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl">Why the Medit T-Series?<br></br> It’s a Simple Choice.</h2>
          </div>
          <div className="mx-auto mt-16 max-w-2xl flex-row gap-8 lg:mx-0 lg:mt-20 lg:max-w-none lg:flex-row lg:items-end grid lg:grid-rows-5">
            <div className="flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-gray-50 p-8 sm:flex-row-reverse sm:items-end  lg:max-w-none lg:flex-none lg:flex-col lg:items-start">
              <p className="flex-none text-3xl font-bold tracking-tight text-gray-900">Superfast Scanning </p>
              <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">

                <p className="mt-2 text-base leading-7 text-gray-600">
                  With our high-quality hardware and optimized software, scan a full-arch in just 8 seconds with the T710, the fastest dental lab scanner!
                </p>
              </div>
            </div>
            <div className="flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-gray-50 p-8 sm:flex-row-reverse sm:items-end  lg:max-w-none lg:flex-none lg:flex-col lg:items-start">
              <p className="flex-none text-3xl font-bold tracking-tight text-gray-900">High-resolution Cameras </p>
              <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">

                <p className="mt-2 text-base leading-7 text-gray-600">
                  Our 5.0MP cameras ensure high-resolution detailed scan data. With the four-camera system, The T710 lab scanner covers a wide scan area, eliminating any blind spots.
                </p>
              </div>
            </div>
            <div className="flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-gray-50 p-8 sm:flex-row-reverse sm:items-end  lg:max-w-none lg:flex-none lg:flex-col lg:items-start">
              <p className="flex-none text-3xl font-bold tracking-tight text-gray-900">Auto-elevation</p>
              <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">

                <p className="mt-2 text-base leading-7 text-gray-600">
                  Say goodbye to stacking jigs for the correct scanning position with our auto-elevation feature. For your ease of use.
                </p>
              </div>
            </div>
            <div className="flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-gray-50 p-8 sm:flex-row-reverse sm:items-end  lg:max-w-none lg:flex-none lg:flex-col lg:items-start">
              <p className="flex-none text-3xl font-bold tracking-tight text-gray-900"> High Accuracy</p>
              <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">

                <p className="mt-2 text-base leading-7 text-gray-600">
                  4-micron accuracy: ISO 12836
                </p>
              </div>
            </div>
            <div className="flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-gray-50 p-8 sm:flex-row-reverse sm:items-end  lg:max-w-none lg:flex-none lg:flex-col lg:items-start">
              <p className="flex-none text-3xl font-bold tracking-tight text-gray-900">Open System</p>
              <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">

                <p className="mt-2 text-base leading-7 text-gray-600">
                  Enjoy the freedom of our open system which allows you to import and export files in STL format so you can design on virtually any software.
                </p>
              </div>
            </div>


          </div>
        </div>

        <div class="bg-white mt-20">
          <div class="relative isolate pt-14">
            <svg class="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]" aria-hidden="true">
              <defs>
                <pattern id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527" width="200" height="200" x="50%" y="-1" patternUnits="userSpaceOnUse">
                  <path d="M100 200V.5M.5 .5H200" fill="none" />
                </pattern>
              </defs>
              <svg x="50%" y="-1" class="overflow-visible fill-gray-50">
                <path d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z" stroke-width="0" />
              </svg>
              <rect width="100%" height="100%" stroke-width="0" fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" />
            </svg>
            <div class="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-40">
              <div class="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">
                <h1 class="mt-10 max-w-lg text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">A better way to ship your projects</h1>
                <p class="mt-6 text-lg leading-8 text-gray-600">The Medit T710 desktop scanner is equipped with a fast scan engine and highly efficient software algorithm which work in tandem to produce a full-arch scan in just 8 seconds. The advanced, high-speed positioning system of the new T-Series is designed for optimal performance for your laboratory, speeding up your workflow and increasing productivity.</p>
                <div class="mt-10 flex items-center gap-x-6">
                  <a href="#" class="text-sm font-semibold leading-6 text-gray-900">Full-arch scan <span aria-hidden="true">→</span></a>
                  <a href="#" class="bg-sky-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500">8 secs</a>

                </div>
              </div>
              <div class="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow">
                <img src={require("../images/medit/image1.jpg")} alt="" />
              </div>
            </div>
          </div>
        </div>

        <div class="bg-gray-50">
          <div class="relative isolate">
            <div class="mx-auto max-w-7xl px-6 lg:flex lg:items-center lg:gap-x-10 lg:px-8">
              <div class="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow">
                <img src={require("../images/medit/image2.jpg")} alt="" />
              </div>
              <div class="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">
                <h1 class="mt-10 max-w-lg text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">Accurate & detailed scan data with our 4 high-resolution cameras</h1>
                <p class="mt-6 text-lg leading-8 text-gray-600">The T710, with its new four 5.0MP-resolution cameras system and blue-light scanning technology, ensures your models and impressions are fully scanned, eliminating all blind spots.</p>
              </div>
            </div>
          </div>

          <ul role="list" class="mx-auto mt-20 grid max-w-2xl px-4 py-4 grid-cols-1 gap-6 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-8">
            <li class="rounded-2xl bg-white px-8 py-10">
              <img class="mx-auto h-48 w-48 rounded-full md:h-56 md:w-56" src={require("../images/medit/data1.jpg")} alt="" />
              {/* <h3 class="mt-6 text-base font-semibold leading-7 tracking-tight text-white">Leonard Krasner</h3> */}
              <p class="text-sm leading-6 text-center text-black">X Brand Scan Data (4 x 5.0MP cameras)</p>
            </li>
            <li class="rounded-2xl bg-white px-8 py-10">
              <img class="mx-auto h-48 w-48 rounded-full md:h-56 md:w-56" src={require("../images/medit/data2.jpg")} alt="" />
              {/* <h3 class="mt-6 text-base font-semibold leading-7 tracking-tight text-white">Leonard Krasner</h3> */}
              <p class="text-sm leading-6 text-center text-black">T710 Scan Data (4 x 5.0MP cameras)</p>
            </li>
            <li class="rounded-2xl bg-white px-8 py-10">
              <img class="mx-auto h-48 w-48 rounded-full md:h-56 md:w-56" src={require("../images/medit/data3.jpg")} alt="" />
              {/* <h3 class="mt-6 text-base font-semibold leading-7 tracking-tight text-white">Leonard Krasner</h3> */}
              <p class="text-sm leading-6 text-center text-black">Scan Data Comparison</p>
            </li>

          </ul>
        </div>

        <div class="bg-white mt-32">
          <div class="relative isolate">
            <div class="mx-auto max-w-7xl px-6 lg:flex lg:items-center lg:gap-x-10 lg:px-8">

              <div class="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">
                <h1 class="mt-10 max-w-lg text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">Lab Scanner Accuracy is the Base of CAD/CAM Work</h1>
                <p class="mt-6 text-lg leading-8 text-gray-600">CAD/CAM dental work requires the highest accuracy to produce well-designed bridges, implants, and bars. Our T-Series ensures the highest-quality scans with high accuracy, adhering to strict international standards, including ISO 12836, ANSI/ADA standard No. 132 and VDI 2634.</p>
              </div>

              <div class="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow">
                <img src={require("../images/medit/image3.jpg")} alt="" />
              </div>
            </div>
          </div>
        </div>

        <div className="mt-32 mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Flexible multi-die scanning</h2>
            <p className="mt-2 text-lg leading-8 text-gray-600">
              Make your work more efficient by using the flexible multi-die to scan a full-arch or partials with multiple dies simultaneously.</p>
          </div>

          <div class="mt-32">
            <div class="bg-gray-50 rounded-xl">
              <div class="relative isolate py-4 px-4">
                <div class="mx-auto max-w-7xl px-6 lg:flex lg:items-center lg:gap-x-10 lg:px-8">

                  <div class="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">
                    <h1 class="mt-10 max-w-lg text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">{pairs[0].first.name}</h1>
                    <p class="mt-6 text-lg leading-8 text-gray-600">{pairs[0].first.description}</p>
                  </div>

                  <div class="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow">
                    <img src={pairs[0].first.img} alt=""
                      className="aspect-[6/3] h-full  max-w-2xl rounded-2xl bg-gray-100 object-cover" />
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-gray-50 rounded-xl mt-32">
              <div class="relative isolate py-4 px-4">
                <div class="mx-auto max-w-7xl px-6 lg:flex lg:items-center lg:gap-x-10 lg:px-8">

                  <div class="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">
                    <h1 class="mt-10 max-w-lg text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">{pairs[0].second.name}</h1>
                    <p class="mt-6 text-lg leading-8 text-gray-600">{pairs[0].second.description}</p>
                  </div>

                  <div class="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow">
                    <img src={pairs[0].second.img} alt=""
                      className="aspect-[29/9] h-full max-w-2xl rounded-2xl bg-gray-100 object-cover" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-32">
            <div class="bg-gray-50 rounded-xl">
              <div class="relative isolate py-4 px-4">
                <div class="mx-auto max-w-7xl px-6 lg:flex lg:items-center lg:gap-x-10 lg:px-8">

                  <div class="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">
                    <h1 class="mt-10 max-w-lg text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">{pairs[1].first.name}</h1>
                    <p class="mt-6 text-lg leading-8 text-gray-600">{pairs[1].first.description}</p>
                  </div>

                  <div class="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow">
                    <img src={pairs[1].first.img} alt=""
                      className="aspect-[14/3] h-full  max-w-2xl rounded-2xl bg-gray-100 object-cover" />
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-gray-50 rounded-xl mt-32">
              <div class="relative isolate py-4 px-4">
                <div class="mx-auto max-w-7xl px-6 lg:flex lg:items-center lg:gap-x-10 lg:px-8">

                  <div class="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">
                    <h1 class="mt-10 max-w-lg text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">{pairs[1].second.name}</h1>
                    <p class="mt-6 text-lg leading-8 text-gray-600">{pairs[1].second.description}</p>
                  </div>

                  <div class="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow">
                    <img src={pairs[1].second.img} alt=""
                      className="aspect-[16/9] w-full max-w-2xl rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-32">
            <div class="bg-gray-50 rounded-xl">
              <div class="relative isolate py-4 px-4">
                <div class="mx-auto max-w-7xl px-6 lg:flex lg:items-center lg:gap-x-10 lg:px-8">
                  <div class="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow">
                    <img src={pairs[2].first.img} alt=""
                      className="aspect-[16/9] w-full  max-w-2xl rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]" />
                  </div>
                  <div class="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">
                    <h1 class="mt-10 max-w-lg text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">{pairs[2].first.name}</h1>
                    <p class="mt-6 text-lg leading-8 text-gray-600">{pairs[2].first.description}</p>
                  </div>


                </div>
              </div>
            </div>
            <div class="bg-gray-50 rounded-xl mt-32">
              <div class="relative isolate py-4 px-4">
                <div class="mx-auto max-w-7xl px-6 lg:flex lg:items-center lg:gap-x-10 lg:px-8">

                  <div class="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">
                    <h1 class="mt-10 max-w-lg text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">{pairs[2].second.name}</h1>
                    <p class="mt-6 text-lg leading-8 text-gray-600">{pairs[2].second.description}</p>
                  </div>

                  <div class="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow">
                    <img src={pairs[2].second.img} alt=""
                      className="aspect-[29/13] w-full max-w-2xl rounded-2xl bg-gray-100 object-cover " />
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>




      </div>

      <div className="relative bg-gray-100 p-8  mx-auto mt-36 max-w-7xl px-4 pb-16 sm:px-6 sm:pb-24 lg:px-8">
        <div className="mx-auto max-w-2xl text-center lg:max-w-4xl">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Technical Specifications</h2>
          {/* <p className="mt-4 text-gray-500">
            Organize is a system to keep your desk tidy and photo-worthy all day long. Procrastinate your work while you
            meticulously arrange items into dedicated trays.
          </p> */}
        </div>

        <dl className="text-center mx-auto mt-16 grid max-w-2xl grid-cols-4 sm:grid-cols-4 lg:max-w-none lg:grid-cols-4 ">
          <div className="border  border-gray-200 pt-4">
            <dt className="font-medium text-gray-900">Category</dt>
            {/* <dd className="mt-2 text-sm text-gray-500">{feature.description}</dd> */}
          </div>
          <div className="border border-gray-200 pt-4">
            <dt className="font-medium text-gray-900">MEDIT T710</dt>
          </div>
          <div className="border border-gray-200 pt-4">
            <dt className="font-medium text-gray-900">MEDIT T510</dt>
          </div>
          <div className="border border-gray-200 pt-4">
            <dt className="font-medium text-gray-900">MEDIT T310</dt>
          </div>

          <div className="border border-gray-200 pt-2">
            <dt className="mt-2 text-sm text-gray-500">Resolution of camera</dt>
          </div>
          <div className="border border-gray-200 pt-2">
            <dt className="mt-2 text-sm text-gray-500">Mono 5.0(MP) x 4</dt>
          </div>
          <div className="border border-gray-200 pt-2">
            <dt className="mt-2 text-sm text-gray-500">Mono 5.0(MP) x 2</dt>
          </div>
          <div className="border border-gray-200 pt-2">
            <dt className="mt-2 text-sm text-gray-500">Mono 5.0(MP) x 2</dt>
          </div>

          <div className="border border-gray-200 pt-2">
            <dt className="mt-2 text-sm text-gray-500">Point Spacing</dt>
          </div>
          <div className="border border-gray-200 pt-2 col-span-3">
            <dt className="mt-2 text-sm text-gray-500 text-center">0.040mm</dt>
          </div>

          <div className="border border-gray-200 pt-2">
            <dt className="mt-2 text-sm text-gray-500">Scan Area</dt>
          </div>
          <div className="border border-gray-200 pt-2 col-span-3">
            <dt className="mt-2 text-sm text-gray-500 text-center">100mm x 73mm x 60mm</dt>
          </div>

          <div className="border border-gray-200 pt-2">
            <dt className="mt-2 text-sm text-gray-500">Scan principle</dt>
          </div>
          <div className="border border-gray-200 pt-2 col-span-3">
            <dt className="mt-2 text-sm text-gray-500 text-center">Phase-shifiting optical triangulation</dt>
          </div>

          <div className="border border-gray-200 pt-2">
            <dt className="mt-2 text-sm text-gray-500">Size</dt>
          </div>
          <div className="border border-gray-200 pt-2 col-span-3">
            <dt className="mt-2 text-sm text-gray-500 text-center">500mm x 271mm x 340mm</dt>
          </div>

          <div className="border border-gray-200 pt-2">
            <dt className="mt-2 text-sm text-gray-500">
              Size</dt>
          </div>
          <div className="border border-gray-200 pt-2 col-span-3">
            <dt className="mt-2 text-sm text-gray-500 text-center">500mm x 271mm x 340mm</dt>
          </div>

          <div className="border border-gray-200 pt-2">
            <dt className="mt-2 text-sm text-gray-500">
            Weight</dt>
          </div>
          <div className="border border-gray-200 pt-2 col-span-3">
            <dt className="mt-2 text-sm text-gray-500 text-center">15kg</dt>
          </div>

          <div className="border border-gray-200 pt-2">
            <dt className="mt-2 text-sm text-gray-500">
            Light source</dt>
          </div>
          <div className="border border-gray-200 pt-2 col-span-3">
            <dt className="mt-2 text-sm text-gray-500 text-center">LED, 150 ANSI-lumens, Blue LED</dt>
          </div>

          <div className="border border-gray-200 pt-2">
            <dt className="mt-2 text-sm text-gray-500">
            Connection</dt>
          </div>
          <div className="border border-gray-200 pt-2 col-span-3">
            <dt className="mt-2 text-sm text-gray-500 text-center">USB 3.0 B Type</dt>
          </div>
          <div className="border border-gray-200 pt-2">
            <dt className="mt-2 text-sm text-gray-500">
            Power</dt>
          </div>
          <div className="border border-gray-200 pt-2 col-span-3">
            <dt className="mt-2 text-sm text-gray-500 text-center">AC 100-240V 50-60 Hz</dt>
          </div>

          <div className="border border-gray-200 pt-2">
            <dt className="mt-2 text-sm text-gray-500">
            Accuracy (ISO 12836)</dt>
          </div>
          <div className="border border-gray-200 pt-2">
            <dt className="mt-2 text-sm text-gray-500 text-center">4µm</dt>
          </div>
          <div className="border border-gray-200 pt-2">
            <dt className="mt-2 text-sm text-gray-500 text-center">7µm</dt>
          </div>
          <div className="border border-gray-200 pt-2">
            <dt className="mt-2 text-sm text-gray-500 text-center">9µm</dt>
          </div>

          <div className="border border-gray-200 pt-2">
            <dt className="mt-2 text-sm text-gray-500">
            Full arch scan speed</dt>
          </div>
          <div className="border border-gray-200 pt-2">
            <dt className="mt-2 text-sm text-gray-500 text-center">8 sec (7cut)</dt>
          </div>
          <div className="border border-gray-200 pt-2">
            <dt className="mt-2 text-sm text-gray-500 text-center">12 sec (7cut)</dt>
          </div>
          <div className="border border-gray-200 pt-2">
            <dt className="mt-2 text-sm text-gray-500 text-center">18 sec (7cut)</dt>
          </div>

          <div className="border border-gray-200 pt-2">
            <dt className="mt-2 text-sm text-gray-500">
            Full arch impression scan speed</dt>
          </div>
          <div className="border border-gray-200 pt-2">
            <dt className="mt-2 text-sm text-gray-500 text-center">45 sec</dt>
          </div>
          <div className="border border-gray-200 pt-2">
            <dt className="mt-2 text-sm text-gray-500 text-center">Not available</dt>
          </div>
          <div className="border border-gray-200 pt-2">
            <dt className="mt-2 text-sm text-gray-500 text-center">Not available</dt>
          </div>

          <div className="border border-gray-200 pt-2">
            <dt className="mt-2 text-sm text-gray-500">
            Auto-elevation</dt>
          </div>
          <div className="border border-gray-200 pt-2">
            <dt className="mt-2 text-sm text-gray-500 text-center">Included</dt>
          </div>
          <div className="border border-gray-200 pt-2">
            <dt className="mt-2 text-sm text-gray-500 text-center">Included</dt>
          </div>
          <div className="border border-gray-200 pt-2">
            <dt className="mt-2 text-sm text-gray-500 text-center">Included</dt>
          </div>

          <div className="border border-gray-200 pt-2">
            <dt className="mt-2 text-sm text-gray-500">
            Color texture</dt>
          </div>
          <div className="border border-gray-200 pt-2">
            <dt className="mt-2 text-sm text-gray-500 text-center">Included</dt>
          </div>
          <div className="border border-gray-200 pt-2">
            <dt className="mt-2 text-sm text-gray-500 text-center">Included</dt>
          </div>
          <div className="border border-gray-200 pt-2">
            <dt className="mt-2 text-sm text-gray-500 text-center">Included</dt>
          </div>

          <div className="border border-gray-200 pt-2">
            <dt className="mt-2 text-sm text-gray-500">
            Flexible scanning</dt>
          </div>
          <div className="border border-gray-200 pt-2">
            <dt className="mt-2 text-sm text-gray-500 text-center">Included</dt>
          </div>
          <div className="border border-gray-200 pt-2">
            <dt className="mt-2 text-sm text-gray-500 text-center">Included</dt>
          </div>
          <div className="border border-gray-200 pt-2">
            <dt className="mt-2 text-sm text-gray-500 text-center">Optional</dt>
          </div>

          <div className="border border-gray-200 pt-2">
            <dt className="mt-2 text-sm text-gray-500">
            Articulator scanning</dt>
          </div>
          <div className="border border-gray-200 pt-2">
            <dt className="mt-2 text-sm text-gray-500 text-center">Included</dt>
          </div>
          <div className="border border-gray-200 pt-2">
            <dt className="mt-2 text-sm text-gray-500 text-center">Included</dt>
          </div>
          <div className="border border-gray-200 pt-2">
            <dt className="mt-2 text-sm text-gray-500 text-center">Optional</dt>
          </div>

          <div className="border border-gray-200 pt-2">
            <dt className="mt-2 text-sm text-gray-500">
            Replica denture</dt>
          </div>
          <div className="border border-gray-200 pt-2">
            <dt className="mt-2 text-sm text-gray-500 text-center">Included</dt>
          </div>
          <div className="border border-gray-200 pt-2">
            <dt className="mt-2 text-sm text-gray-500 text-center">Optional</dt>
          </div>
          <div className="border border-gray-200 pt-2">
            <dt className="mt-2 text-sm text-gray-500 text-center">Optional</dt>
          </div>

          <div className="border border-gray-200 pt-2">
            <dt className="mt-2 text-sm text-gray-500">
            Orthodontic scanning</dt>
          </div>
          <div className="border border-gray-200 pt-2">
            <dt className="mt-2 text-sm text-gray-500 text-center">Included</dt>
          </div>
          <div className="border border-gray-200 pt-2">
            <dt className="mt-2 text-sm text-gray-500 text-center">Optional</dt>
          </div>
          <div className="border border-gray-200 pt-2">
            <dt className="mt-2 text-sm text-gray-500 text-center">Optional</dt>
          </div>

          <div className="border border-gray-200 pt-2">
            <dt className="mt-2 text-sm text-gray-500">
            Impression scanning</dt>
          </div>
          <div className="border border-gray-200 pt-2">
            <dt className="mt-2 text-sm text-gray-500 text-center">Included</dt>
          </div>
          <div className="border border-gray-200 pt-2">
            <dt className="mt-2 text-sm text-gray-500 text-center">Not available</dt>
          </div>
          <div className="border border-gray-200 pt-2">
            <dt className="mt-2 text-sm text-gray-500 text-center">Not available</dt>
          </div>
          
          
        </dl>
      </div>
    </div>
  )
}
