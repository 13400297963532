const posts = [
  {
    id: 1,
    title: 'TRIOS 5 Wireless',
    imageUrl:
      require("../images/scanner/trios5 portfolio.png"),
    description:"Intelligent. The ultimate TRIOS."
  },
  {
    id: 2,
    title: 'TRIOS 4 Wireless',
    imageUrl:
      require("../images/scanner/trios4 portfolio.png"),
    description:"Advanced. Extra power."
  },
  {
    id: 3,
    title: 'TRIOS 3 Wired',
    imageUrl:
      require("../images/scanner/trios3 portfolio.png"),
    description:"Proven. Always a winner."
  },
];
const features = [

  { name: 'Dimensions', description: 'HWL (cm): 35 x 44 x 43' },
  { name: 'Cameras', description: '4 x 5 MP' },
  { name: 'Accuracy (ISO 12836)', description: '4 μm' },
  { name: 'Scan speed (full arch)', description: '9 sec' },
  { name: 'Scan speed (full arch impression)', description: '45 sec' },
  { name: 'Texture', description: 'Color' },
  { name: 'Scanning strategy', description: 'All-in-one / die in model' },
  { name: 'Dual model scanning', description: '✓' },
  { name: 'Scanner sharing', description: 'Ethernet' },
  { name: 'Auto-start', description: '✓' },
  { name: 'Tilt-free scanning', description: '✓' },
  { name: 'Legacy indication tools', description: 'Yes + F8 tools' },
  { name: 'Server client set-up', description: 'Optional' },
  { name: 'ScanIt Dental scanner software', description: '✓' },
  { name: 'Dental System (with option for cheaper scale-up)', description: 'Add-on' },
  { name: 'Studio Apps', description: 'Add-on' },
  { name: '3Shape LMS (Essentials free / Premium paid)', description: '✓' },
  { name: 'Warranty with LabCare', description: '5 years' },
  { name: 'Express replacement', description: '✓' },
];
const series = [
  {
    name: "E2 RESTORATIVE",
    img: require("../images/scanner/E2.png"),
    features: [
      {name: "Camera", detail: "2 x 5 MP"},
      {name: "Accuracy (ISO 12836)", detail: "10 μm"},
      {name: "Scan speed (full arch)", detail: "24 sec"},
      {name: "Scan speed (full arch impression)", detail: "72 sec"},
      {name: "Texture", detail: "B/W"},
      {name: "Scanning strategy", detail: "Standard"},
    ]
  },
  {
    name: "E2 ORTHODONTIC 2 x 5 MP Blue LED",
    img: require("../images/scanner/E2.png"),
    features: [
      {name: "Camera", detail: "Multiline"},
      {name: "Accuracy (ISO 12836)", detail: "10 μm"},
      {name: "Scan time (plaster model)", detail: "34 sec"},
      {name: "Scan time (impression)", detail: "57 sec"},
      {name: "Texture", detail: "B/W"},
    ]
  },
  {
    name: "E3 RESTORATIVE",
    img: require("../images/scanner/E3.png"),
    features: [
      {name: "Camera", detail: "2 x 5 MP"},
      {name: "Accuracy (ISO 12836)", detail: "7 μm"},
      {name: "Scan speed (full arch)", detail: "18 sec"},
      {name: "Scan speed (full arch impression)", detail: "64 sec"},
      {name: "Texture", detail: "Color"},
      {name: "Scanning strategy", detail: "Standard"},
    ]
  },
  {
    name: "E3 ORTHODONTIC 2 x 5 MP Blue LED",
    img: require("../images/scanner/E3.png"),
    features: [
      {name: "Camera", detail: "Multiline"},
      {name: "Accuracy (ISO 12836)", detail: "7 μm"},
      {name: "Scan time (plaster model)", detail: "26 sec"},
      {name: "Scan time (impression)", detail: "41 sec"},
      {name: "Texture", detail: "Color"},
    ]
  },
  {
    name: "E4 RESTORATIVE",
    img: require("../images/scanner/E4.png"),
    features: [
      {name: "Camera", detail: "4 x 5 MP"},
      {name: "Accuracy (ISO 12836)", detail: "4 μm"},
      {name: "Scan speed (full arch)", detail: "9 sec"},
      {name: "Scan speed (full arch impression)", detail: "45 sec"},
      {name: "Texture", detail: "Color"},
      {name: "Scanning strategy", detail: "Die-in-model"},
    ]
  },
  {
    name: "E4 ORTHODONTIC 4 x 5 MP Blue LED",
    img: require("../images/scanner/E4.png"),
    features: [
      {name: "Camera", detail: "Multiline"},
      {name: "Accuracy (ISO 12836)", detail: "4 μm"},
      {name: "Scan time (plaster model)", detail: "17 sec"},
      {name: "Scan time (impression)", detail: "25 sec"},
      {name: "Texture", detail: "Color"},
    ]
  },
  
]
export default function Scanners() {
  return (
    <div className="bg-white py-24 sm:py-32">
      {/* F8 */}
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Meet F8</h2>
        </div>
        <div className="mx-auto mt-16 max-w-2xl flex-col gap-8 lg:mx-0 lg:mt-20 lg:max-w-none lg:flex-row lg:items-end grid lg:grid-cols-3">
            <div className="flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-gray-50 p-8 sm:flex-row-reverse sm:items-end  lg:max-w-none lg:flex-none lg:flex-col lg:items-start">
              <p className="flex-none text-3xl font-bold tracking-tight text-gray-900">More efficiency </p>
              <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
                
                <p className="mt-2 text-base leading-7 text-gray-600">
                Reduce technician’s hands-on time with the intuitive place-and-scan design. 
                </p>
              </div>
            </div>
            <div className="flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-gray-50 p-8 sm:flex-row-reverse sm:items-end  lg:max-w-none lg:flex-none lg:flex-col lg:items-start">
              <p className="flex-none text-3xl font-bold tracking-tight text-gray-900">More versatility </p>
              <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
                
                <p className="mt-2 text-base leading-7 text-gray-600">
                Provide a complete range of quality services to your doctors with less effort.
                </p>
              </div>
            </div>
            <div className="flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-gray-50 p-8 sm:flex-row-reverse sm:items-end  lg:max-w-none lg:flex-none lg:flex-col lg:items-start">
              <p className="flex-none text-3xl font-bold tracking-tight text-gray-900">More speed </p>
              <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
                
                <p className="mt-2 text-base leading-7 text-gray-600">
                Stay on top of your caseload with lean, automated scan-to-design workflows.
                </p>
              </div>
            </div>
           
            
          </div>
      </div>

      <div className="overflow-hidden mt-5">
        <div className="mx-auto max-w-7xl px-6  lg:px-8">
          <div className="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
            <div className="w-full max-w-xl lg:shrink-0 xl:max-w-2xl">
              <h1 className="text-4xl font-bold tracking-tight text-gray-900">
              Open design
              </h1>
              <p className="relative mt-6 text-lg leading-8 text-gray-600 sm:max-w-md lg:max-w-none">
              We've considered every small detail. Placement of models is intuitive and fast thanks to the open scanner design and magnetic place-and-scan system. And auto-start ensures scanning begins as soon as your models are in place.
              </p>
            </div>
            <div className="mt-14 flex justify-end gap-8 sm:justify-start  lg:mt-0 lg:pl-0">
              <div className="mx-auto w-72 flex-none space-y-8  sm:ml-0  lg:order-last lg:pt-36 xl:order-none ">
                <div className="relative">
                  <img
                    src={require("../images/scanner/Open design.png")}
                    alt=""
                    className="aspect-[3/2] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                  />
                  <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                </div>
              </div>
              
              
            </div>
          </div>
        </div>
      </div>

      <div className="overflow-hidden mt-5">
        <div className="mx-auto max-w-7xl px-6  lg:px-8">
          <div className="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
            <div className="w-full max-w-xl lg:shrink-0 xl:max-w-2xl">
              <h1 className="text-4xl font-bold tracking-tight text-gray-900">
              Scanner tools
              </h1>
              <p className="relative mt-6 text-lg leading-8 text-gray-600 sm:max-w-md lg:max-w-none">
              We’ve introduced new scanner tools such as a height adapter, articulator plate and a new impression fixture, that open up new workflow efficiency possibilities. But we will of course also continue to support most older tools.
              </p>
            </div>
            <div className="mt-14 flex justify-end gap-8 sm:justify-start  lg:mt-0 lg:pl-0">
              <div className="mx-auto w-72 flex-none space-y-8  sm:ml-0  lg:order-last lg:pt-36 xl:order-none ">
                <div className="relative">
                  <img
                    src={require("../images/scanner/Scanner tools.png")}
                    alt=""
                    className="aspect-[3/2] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                  />
                  <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                </div>
              </div>
              
              
            </div>
          </div>
        </div>
      </div>

      <div className="overflow-hidden mt-5">
        <div className="mx-auto max-w-7xl px-6  lg:px-8">
          <div className="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
            <div className="w-full max-w-xl lg:shrink-0 xl:max-w-2xl">
              <h1 className="text-4xl font-bold tracking-tight text-gray-900">
              Share between stations
              </h1>
              <p className="relative mt-6 text-lg leading-8 text-gray-600 sm:max-w-md lg:max-w-none">
              For many labs, it’s cost effective to share a scanner. Therefore, we’ve simplified scanner sharing options between workstations through the open design and ethernet.
              </p>
            </div>
            <div className="mt-14 flex justify-end gap-8 sm:justify-start  lg:mt-0 lg:pl-0">
              <div className="mx-auto w-72 flex-none space-y-8  sm:ml-0  lg:order-last lg:pt-36 xl:order-none ">
                <div className="relative">
                  <img
                    src={require("../images/scanner/Share between stations.png")}
                    alt=""
                    className="aspect-[3/2] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                  />
                  <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                </div>
              </div>
              
              
            </div>
          </div>
        </div>
      </div>

      <div className="overflow-hidden mt-5">
        <div className="mx-auto max-w-7xl px-6  lg:px-8">
          <div className="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
            <div className="w-full max-w-xl lg:shrink-0 xl:max-w-2xl">
              <h1 className="text-4xl font-bold tracking-tight text-gray-900">
              Flexible workflows
              </h1>
              <p className="relative mt-6 text-lg leading-8 text-gray-600 sm:max-w-md lg:max-w-none">
              This scanner goes far beyond the crown & bridge or implant bar. From upper/lower, die-in-model, articulator and impressions, what you can achieve is virtually limitless.
              </p>
            </div>
            <div className="mt-14 flex justify-end gap-8 sm:justify-start  lg:mt-0 lg:pl-0">
              <div className="mx-auto w-72 flex-none space-y-8  sm:ml-0  lg:order-last lg:pt-36 xl:order-none ">
                <div className="relative">
                  <img
                    src={require("../images/scanner/Flexible workflows.png")}
                    alt=""
                    className="aspect-[3/2] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                  />
                  <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                </div>
              </div>
              
              
            </div>
          </div>
        </div>
      </div>

      <div className="relative bg-gray-100 p-8  mx-auto mt-36 max-w-7xl px-4 pb-16 sm:px-6 sm:pb-24 lg:px-8">
        <div className="mx-auto max-w-2xl text-center lg:max-w-4xl">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Technical Specifications</h2>
          {/* <p className="mt-4 text-gray-500">
            Organize is a system to keep your desk tidy and photo-worthy all day long. Procrastinate your work while you
            meticulously arrange items into dedicated trays.
          </p> */}
        </div>

        <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:max-w-none lg:grid-cols-7 lg:gap-x-8">
          {features.map((feature) => (
            <div key={feature.name} className="border-t border-gray-200 pt-4">
              <dt className="font-medium text-gray-900">{feature.name}</dt>
              <dd className="mt-2 text-sm text-gray-500">{feature.description}</dd>
            </div>
          ))}
        </dl>
      </div>
      {/* E series */}
      <div className="mx-auto max-w-7xl px-6 lg:px-8 mt-32">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">E Series</h2>
        </div>
        <div className="mx-auto mt-16 max-w-2xl flex-col gap-8 lg:mx-0 lg:mt-20 lg:max-w-none lg:flex-row lg:items-end grid lg:grid-cols-3">
            <div className="flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-gray-50 p-8 sm:flex-row-reverse sm:items-end  lg:max-w-none lg:flex-none lg:flex-col lg:items-start">
              <p className="flex-none text-3xl font-bold tracking-tight text-gray-900">Value for money</p>
              <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
                
                <p className="mt-2 text-base leading-7 text-gray-600">
                  Advanced features and CAD design workflows at an affordable price.
                </p>
              </div>
            </div>
            <div className="flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-gray-50 p-8 sm:flex-row-reverse sm:items-end  lg:max-w-none lg:flex-none lg:flex-col lg:items-start">
              <p className="flex-none text-3xl font-bold tracking-tight text-gray-900">Improve productivity</p>
              <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
                
                <p className="mt-2 text-base leading-7 text-gray-600">
                Complete more cases in less time - start scanning upon placement.
                </p>
              </div>
            </div>
            <div className="flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-gray-50 p-8 sm:flex-row-reverse sm:items-end  lg:max-w-none lg:flex-none lg:flex-col lg:items-start">
              <p className="flex-none text-3xl font-bold tracking-tight text-gray-900">Optimized for speed</p>
              <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
                
                <p className="mt-2 text-base leading-7 text-gray-600">
                20% increased scan speed compared to the previous generation of E scanners.
                </p>
              </div>
            </div>
           
            
          </div>
      </div>

      <div className="overflow-hidden mt-5">
        <div className="mx-auto max-w-7xl px-6  lg:px-8">
          <div className="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
            <div className="w-full max-w-xl lg:shrink-0 xl:max-w-2xl">
              {/* <h1 className="text-4xl font-bold tracking-tight text-gray-900">
              Open design
              </h1> */}
              <p className="relative mt-6 text-lg leading-8 text-gray-600 sm:max-w-md lg:max-w-none">
              The ability to connect your analog cases to streamlined digital workflows has a name: the E series. High ISO documented accuracy, high speed, and many advanced scanning features.
              </p>
            </div>
            <div className="mt-14 flex justify-end gap-8 sm:justify-start  lg:mt-0 lg:pl-0">
              <div className="mx-auto w-72 flex-none space-y-8  sm:ml-0  lg:order-last lg:pt-36 xl:order-none ">
                <div className="relative">
                  <img
                    src={require("../images/scanner/Solid scanner range.png")}
                    alt=""
                    className="aspect-[3/2] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                  />
                  <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                </div>
              </div>
              
              
            </div>
          </div>
        </div>
      </div>

      <div className="overflow-hidden mt-5">
        <div className="mx-auto max-w-7xl px-6  lg:px-8">
          <div className="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
            <div className="w-full max-w-xl lg:shrink-0 xl:max-w-2xl">
              {/* <h1 className="text-4xl font-bold tracking-tight text-gray-900">
              Scanner tools
              </h1> */}
              <p className="relative mt-6 text-lg leading-8 text-gray-600 sm:max-w-md lg:max-w-none">
              From the entry-level E2 scanner for labs working in crown and bridge to the high-performance E4 scanner for full-service digital labs, the E Series portfolio offers a solution perfectly matched to the needs of your lab.
              </p>
            </div>
            <div className="mt-14 flex justify-end gap-8 sm:justify-start  lg:mt-0 lg:pl-0">
              <div className="mx-auto w-72 flex-none space-y-8  sm:ml-0  lg:order-last lg:pt-36 xl:order-none ">
                <div className="relative">
                  <img
                    src={require("../images/scanner/Scratch free.png")}
                    alt=""
                    className="aspect-[3/2] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                  />
                  <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                </div>
              </div>
              
              
            </div>
          </div>
        </div>
      </div>
      
      {series.map(product=>(
        <div className="overflow-hidden mt-10">
        <div className="mx-auto max-w-7xl px-6  lg:px-8">
          <div className="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
            <div className="w-full max-w-xl lg:shrink-0 xl:max-w-2xl">
              <h1 className="text-4xl font-bold tracking-tight text-gray-900">
              {product.name}
              </h1>
              <p className="relative mt-6 text-lg leading-8 text-gray-600 sm:max-w-md lg:max-w-none">
              <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-2 gap-x-6 gap-y-10  sm:gap-y-16 lg:max-w-none lg:gap-x-8 bg-gray-100 p-8">
                {product.features.map((feature) => (
                  <div key={feature.name} className="border-t border-gray-200 pt-4">
                    <dt className="font-medium text-gray-900">{feature.name}</dt>
                    <dd className="mt-2 text-sm text-gray-500">{feature.detail}</dd>
                  </div>
                ))}
              </dl>
              </p>
            </div>
            <div className="mt-14 flex justify-end gap-8 sm:justify-start  lg:mt-0 lg:pl-0">
              <div className="mx-auto w-72 flex-none space-y-8  sm:ml-0  lg:order-last lg:pt-36 xl:order-none ">
                <div className="relative">
                  <img
                    src={product.img}
                    alt=""
                    className="aspect-[3/2] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                  />
                  <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                </div>
              </div>
              
              
            </div>
          </div>
        </div>
      </div>
      ))}
      

      <div className="mt-20 mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">TRIOS SERIES</h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
          That’s five generations of pushing the limits of innovation in digital dentistry. Of surpassing expectations to deliver unmatched ease-of-use, award-winning design, and peace of mind.

          And of breaking barriers by seamlessly connecting dentists with leading solutions and labs through the dental world’s most collaborative platform: 3Shape Unite.
          </p>
        </div>
        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {posts.map((post) => (
            <article key={post.id} className="flex flex-col items-start justify-between">
              <div className="relative w-full">
                <img
                  src={post.imageUrl}
                  alt=""
                  className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                />
                <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
              </div>
              <div className="max-w-xl">
                <div className="mt-8 flex items-center gap-x-4 text-xs">
                  
                </div>
                <div className="group relative">
                  <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                    <a href={post.href}>
                      <span className="absolute inset-0" />
                      {post.title}
                    </a>
                  </h3>
                  <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">{post.description}</p>
                </div>
              </div>
            </article>
          ))}
        </div>
      </div>
    </div>
  )
}
