import React from "react";
import Header from './components/header.js';
import Home from './pages/home.js';
import About from './pages/about.js';
import Products from './components/products.js';
import Contact from './components/contact.js';
import Footer from "./components/footer.js";
import Product from "./pages/product.js";
import ScannerPage from "./pages/scannerpage.js";
import MeditPage from "./pages/meditpage.js";
import AsigaPage from "./pages/asigapage.js";
import MillingPage from "./pages/millingpage.js";
import IvoclarPage from "./pages/ivoclarpage.js";
import Labsheet from "./pages/labsheet.js";
function App() {

  let page;

  let path = window.location.pathname;
  
  if(path.startsWith('/product/')){
    let paths = path.split("/")
    page = <Product productID={parseInt(paths[2])} />;
  }else{
    switch (window.location.pathname){
      case "/milling":
        page =<MillingPage/>;
        break;
      case "/ivoclar":
          page =<IvoclarPage/>;
          break;
      case "/about":
        page = <About/>;
        break;
      case "/asiga":
          page = <AsigaPage/>;
          break;
      case "/scanner":
          page = <ScannerPage/>;
          break;
      case "/medit":
          page = <MeditPage/>;
          break;
      case "/products":
        page = <Products/>;
        break;
      case "/labsheet":
        page = <Labsheet/>;
        break;
      case "/contact":
        page = <Contact/>;
        break;
      default:
        page = <Home/>;
        break;
    }
  }

 

  return (
    <div className="App font-custom">

      <Header/>
      
      {page}

      <Footer />
    </div>
  );
}

export default App;
