import Asiga from '../components/asiga.js';
import SubHero from '../components/subhero.js';

export default function AsigaPage() {
  return (
    <div>
        <SubHero pageID="4"></SubHero>
        <Asiga/>
    </div>
  )
}