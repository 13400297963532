import SubHero from '../components/subhero.js';
import Medits from '../components/medit.js';

export default function MeditPage() {
  return (
    <div>
        <SubHero pageID="3"></SubHero>
        <Medits/>
    </div>
  )
}