const products = [
  {
    name: 'ZIRCONIA',
    brief: 'this is a show breif',
    features: [
      { name: 'Material', description: 'Designed by Good Goods, Inc.', },
      { name: 'Price', description: '100hkd', },
      { name: 'Material', description: 'Designed by Good Goods, Inc.', },
      { name: 'Price', description: '100hkd', },
    ],
    images: [
      { src: require('../images/products/1.png') },
      { src: require('../images/products/2.png') },
      { src: require('../images/products/3.png') },
      { src: require('../images/products/4.png') }
    ]
  },
  {
    name: 'ZIRCONIA',
    brief: 'this is a show breif',
    features: [
      { name: 'Material', description: 'Designed by Good Goods, Inc.', },
      { name: 'Price', description: '100hkd', },
      { name: 'Material', description: 'Designed by Good Goods, Inc.', },
      { name: 'Price', description: '100hkd', },
    ],
    images: [
      { src: require('../images/products/1.png') },
      { src: require('../images/products/2.png') },
      { src: require('../images/products/3.png') },
      { src: require('../images/products/4.png') }
    ]
  },
  {
    name: 'ZIRCONIA',
    brief: 'this is a show breif',
    features: [
      { name: 'Material', description: 'Designed by Good Goods, Inc.', },
      { name: 'Price', description: '100hkd', },
      { name: 'Material', description: 'Designed by Good Goods, Inc.', },
      { name: 'Price', description: '100hkd', },
    ],
    images: [
      { src: require('../images/products/1.png') },
      { src: require('../images/products/2.png') },
      { src: require('../images/products/3.png') },
      { src: require('../images/products/4.png') }
    ]
  },
  {
    name: 'ZIRCONIA',
    brief: 'this is a show breif',
    features: [
      { name: 'Material', description: 'Designed by Good Goods, Inc.', },
      { name: 'Price', description: '100hkd', },
      { name: 'Material', description: 'Designed by Good Goods, Inc.', },
      { name: 'Price', description: '100hkd', },
    ],
    images: [
      { src: require('../images/products/1.png') },
      { src: require('../images/products/2.png') },
      { src: require('../images/products/3.png') },
      { src: require('../images/products/4.png') }
    ]
  },
  {
    name: 'ZIRCONIA',
    brief: 'this is a show breif',
    features: [
      { name: 'Material', description: 'Designed by Good Goods, Inc.', },
      { name: 'Price', description: '100hkd', },
      { name: 'Material', description: 'Designed by Good Goods, Inc.', },
      { name: 'Price', description: '100hkd', },
    ],
    images: [
      { src: require('../images/products/1.png') },
      { src: require('../images/products/2.png') },
      { src: require('../images/products/3.png') },
      { src: require('../images/products/4.png') }
    ]
  },
  {
    name: 'ZIRCONIA',
    brief: 'this is a show breif',
    features: [
      { name: 'Material', description: 'Designed by Good Goods, Inc.', },
      { name: 'Price', description: '100hkd', },
      { name: 'Material', description: 'Designed by Good Goods, Inc.', },
      { name: 'Price', description: '100hkd', },
    ],
    images: [
      { src: require('../images/products/1.png') },
      { src: require('../images/products/2.png') },
      { src: require('../images/products/3.png') },
      { src: require('../images/products/4.png') }
    ]
  },
]

export default function Product(props) {
  const product = products[props.productID - 1];
  const features = product.features;
  return (
    <div className="bg-white">
      <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-16 px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
        <div>
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{product.name}</h2>
          <p className="mt-4 text-gray-500">
            {product.brief}
          </p>

          <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
            {features.map((feature) => (
              <div key={feature.name} className="border-t border-gray-200 pt-4">
                <dt className="font-medium text-gray-900">{feature.name}</dt>
                <dd className="mt-2 text-sm text-gray-500">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
        <div className="grid grid-cols-2 grid-rows-2 gap-4 sm:gap-6 lg:gap-8">
          {
            product.images.map((image) => (
              <img
                src={image.src}
                alt={product.name}
                className="rounded-lg bg-gray-100"
              />
            ))
          }

        </div>
      </div>
    </div>
  )
}
