// import Stats from '../components/features.js';
import Milling from '../components/milling.js';
import SubHero from '../components/subhero.js';

export default function MillingPage() {
  return (
    <div>
        <SubHero pageID="5"></SubHero>
        <Milling/>
        {/* <Stats/> */}
    </div>
  )
}