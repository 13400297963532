import Scanners from '../components/scanners.js';
import SubHero from '../components/subhero.js';

export default function ScannerPage() {
  return (
    <div>
        <SubHero pageID="1"/>
        <Scanners/>
    </div>
  )
}