/* eslint-disable array-callback-return */
const callouts = [
  {
    id: 1,
    imgSrc: require("../images/animate/1.jpg"),
  },
  {
    id: 2,
    imgSrc: require("../images/animate/2.jpg"),
  },
  {
    id: 3,
    imgSrc: require("../images/animate/3.jpg"),
  },
  {
    id: 4,
    imgSrc: require("../images/animate/4.jpg"),
  },
  {
    id: 5,
    imgSrc: require("../images/animate/5.jpg"),
  },
  {
    id: 6,
    imgSrc: require("../images/animate/6.jpg"),
  },

]

export default function Display() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl py-16 sm:py-24 lg:max-w-none lg:py-32">
          <div className="mt-6 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-6 lg:space-y-0">
            {callouts.map((callout) => {
              if (callout.id % 3 === 0) {
                return (
                  <div key={callout.name} className="group relative">
                    <div className="relative h-80 w-full overflow-hidden rounded-lg bg-white sm:aspect-h-1 sm:aspect-w-2 lg:aspect-h-1 lg:aspect-w-1 group-hover:opacity-75 sm:h-64 mb-20 animate-float">
                      <img
                        src={callout.imgSrc}
                        alt={callout.imageAlt}
                        className="h-full w-full object-cover object-center  animate-pulse"
                      />
                    </div>
                  </div>
                )
              }else if(callout.id%3===1){
                return (
                  <div key={callout.name} className="group relative">
                    <div className="relative h-80 w-full overflow-hidden rounded-lg bg-white sm:aspect-h-1 sm:aspect-w-2 lg:aspect-h-1 lg:aspect-w-1 group-hover:opacity-75 sm:h-64 mb-20 animate-float-1s" >
                      <img
                        src={callout.imgSrc}
                        alt={callout.imageAlt}
                        className="h-full w-full object-cover object-center animate-pulse"
                      />
                    </div>
                  </div>
                )
              }else if(callout.id%3===2){
                return (
                  <div key={callout.name} className="group relative">
                    <div className="relative h-80 w-full overflow-hidden rounded-lg bg-white sm:aspect-h-1 sm:aspect-w-2 lg:aspect-h-1 lg:aspect-w-1 group-hover:opacity-75 sm:h-64 mb-20 animate-float-2s">
                      <img
                        src={callout.imgSrc}
                        alt={callout.imageAlt}
                        className="h-full w-full object-cover object-center animate-pulse"
                      />
                    </div>
                  </div>
                )
              }
            }

            )}
          </div>
        </div>
      </div>
    </div>
  );
}

