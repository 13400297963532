import Ivolar from '../components/ivoclar.js';
import SubHero from '../components/subhero.js';


export default function IvoclarPage() {
  return (
    <div>
        <SubHero pageID="2"></SubHero>
        <Ivolar/>
    </div>
  )
}