import Hero from '../components/hero.js';
import Features from "../components/features.js"

export default function Home() {
  return (
    <div>
        <Hero/>
        <Features/>
    </div>
  )
}