const navigation = {
  pages: [
    { name: 'Home', href: '/' },
    { name: 'About', href: '/about' },
    { name: 'Products', href: '/product' },
    { name: 'Contact', href: '/contact' }
  ],
  contact: [
    { name: '852-98248785', href: 'tel:852-98248785' },
    { name: 'ECODENTALLABHK@GMAIL.COM', href: 'mailto:ECODENTALLABHK@GMAIL.COM' },
  ],
  location: [
    { name: 'UNIT 06A,9/FL, SING WIN FACTORY BUILDING', href: '#' },
    { name: '15-17 SHING YIP STREET,KWUN TONG,KOWLOON.', href: '#' }
  ],
  legal: [
    { name: 'Claim', href: '#' },
    { name: 'Privacy', href: '#' },
    { name: 'Terms', href: '#' },
  ]
}

export default function Footer() {
return (
  <footer className="bg-gradient-to-br from-cyan-300 to-green-400" aria-labelledby="footer-heading">
    <h2 id="footer-heading" className="sr-only">
      Footer
    </h2>
    <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
      <div className="xl:grid xl:grid-cols-3 xl:gap-8">
        <div className="space-y-8">
          <img
            className="h-24 bg-white"
            src={ require('../images/logo.png') }
            alt="DreamTec"
          />
          <p className="text-3xl  text-white">
          
          </p>
          <p className="text-2xl text-white">
          We are committed to providing the highest quality products and services to our clients.
          </p>
        </div>
        <div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0 ml-auto">
        <div className="md:grid md:gap-8">
            <div>
              <h3 className="text-3xl font-semibold leading-6 text-white">Contact Us</h3>
              <ul className="mt-6 space-y-4">
                {navigation.contact.map((item) => (
                  <li key={item.name}>
                    <a href={item.href} className="text-base leading-6 text-white hover:text-white">
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="md:grid md:gap-8">
            <div>
              <h3 className="text-3xl font-semibold leading-6 text-white">Address</h3>
              <ul className="mt-6 space-y-4">
                {navigation.location.map((item) => (
                  <li key={item.name}>
                    <a href={item.href} className="text-base leading-6 text-white hover:text-white">
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-16 border-t border-white/10 pt-8 sm:mt-20 lg:mt-24 flex justify-between">
        <div className="flex items-center">
          <p className="text-base leading-5 text-white">ECO-DENTAL LAB &copy; 2023 All rights reserved.</p>
        </div>
        <div className="flex items-center">
          {/* <p className="text-base leading-5 text-white text-right mr-4">Powered By <u><a href='https://dreamtec.ai'>DreamTec.ai</a></u></p>
          <a href='https://dreamtec.ai'><img src={require('../images/logo-dt.png')} className='h-16 w-auto' alt="DreamTec.ai Logo" /></a> */}
        </div>
      </div>
    </div>
  </footer>
)
}
