const callouts = [
  {
    name: '3SHAPE TRIOS SCANNERS',
    description: 'The world’s most recognized intraoral scanner brand',
    imageSrc: require('../images/3shapeHero.jpg'),
    imageAlt: 'The world’s most recognized intraoral scanner brand',
    href: '/scanner',
  },
  {
    name: 'MEDIT',
    description: 'The Easy Entry into Digital Dentistry',
    imageSrc: require('../images/meditHero.jpg'),
    imageAlt: 'MEDIT',
    href: '/medit',
  },
  {
    name: 'ASIGA',
    description: 'Precision 3D printers and materials.',
    imageSrc: require('../images/asigaHero.jpg'),
    imageAlt: 'ASIGA',
    href: '/asiga',
  },
  { name: 'IVOCLAR', 
  description: 'The new Programat ceramic furnaces', 
  imageSrc: require('../images/ivoclarHero.jpg'),
    imageAlt: 'IVOCLAR',
  href: '/ivoclar', },
  {
    name: 'MILLING',
    description: 'Milling service.',
    imageSrc: require('../images/millingHero.jpg'),
    imageAlt: 'MILLING SERVICE',
    href: '/milling',
  },
  {
    name: 'SEND CASES',
    description: 'Send cases.',
    imageSrc: require('../images/labsheet.jpg'),
    imageAlt: 'SEND CASE',
    href: '/labsheet',
  },
  
]

export default function Products() {
  return (
    <div className="bg-gray-100">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl py-16 sm:py-24 lg:max-w-none lg:py-32">
          <h2 className="text-5xl font-bold text-gray-900 text-center mb-20">Products</h2>

          <div className="mt-6 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-6 lg:space-y-0">
            {callouts.map((callout) => (
              <div key={callout.name} className="group relative">
                <div className="relative h-80 w-full overflow-hidden rounded-lg bg-white sm:aspect-h-1 sm:aspect-w-2 lg:aspect-h-1 lg:aspect-w-1 group-hover:opacity-75 sm:h-64">
                  <img
                    src={callout.imageSrc}
                    alt={callout.imageAlt}
                    className="h-full w-full object-cover object-center"
                  />
                </div>
                <h3 className="mt-6 text-2xl text-gray-500">
                  <a href={callout.href}>
                    <span className="absolute inset-0" />
                    {callout.name}
                  </a>
                </h3>
                <p className="text-2xl font-semibold text-gray-900 mb-10">{callout.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
