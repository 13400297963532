/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-redundant-roles */

export default function Labsheet() {
  return (
    <div className="bg-gray-100">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl py-16 sm:py-24 lg:max-w-none lg:py-32">
          <h2 className="text-5xl font-bold text-gray-900 text-center mb-20">SEND CASES​</h2>

          <div class="bg-white">
            <div class="relative isolate"><div class="pl-6 mx-auto max-w-7xl lg:flex lg:items-center lg:gap-x-0 lg:pl-8 ">
              <div class="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">
                <h1 class="max-w-lg text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">Intraoral Scan </h1>
                {/* <p class="mt-6 text-lg leading-8 text-gray-600">Take a digital impression with an intraoral scanner</p> */}
              </div>
              <div class="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 ">
                <img src={require("../images/labsheet/labsheet1.jpg")} alt=""
                  className="aspect-[2/1] w-full bg-gray-100 object-cover sm:aspect-[4/2] lg:aspect-[2/1] max-w-xl" />
              </div>
            </div>
            </div>
          </div>

          <div class="bg-white mb-20 mt-20">

          </div>

          <div class="bg-white mb-20">
            <div class="relative isolate"><div class="mx-auto max-w-7xl lg:flex lg:items-center lg:gap-x-0">
            <div class="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 ">
                <img src={require("../images/labsheet/labsheet2.jpg")} alt=""
                  className="aspect-[2/1] w-full bg-gray-100 object-cover sm:aspect-[4/2] lg:aspect-[2/1] max-w-xl" />
              </div>
              <div class="mx-auto max-w-2xl lg:mx-0 lg:flex-auto pl-6 lg:pl-8">
                <h1 class="max-w-lg text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">Physical Impression </h1>
                {/* <p class="mt-6 text-lg leading-8 text-gray-600">Design the crown or bridge in the CAD/CAM software</p> */}
              </div>
              
            </div>
            </div>            
          </div>
        </div>
      </div>
    </div>
  )
}
