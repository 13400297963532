const posts = [
  {
    id: 1,
    title: 'MAX',
    imageUrl:
      require("../images/asiga/asiga-max-printer.jpg"),
    description:"Minimum footprint, maximum productivity."
  },
  {
    id: 2,
    title: 'MAX X',
    imageUrl:
      require("../images/asiga/asiga-max-x-printer.jpg"),
    description:"Our highest resolution 3D printer."
  },
  {
    id: 3,
    title: 'PRO 4K',
    imageUrl:
      require("../images/asiga/asiga-pro-4k-front.jpg"),
    description:"3D print precision meets volume."
  },
  {
    id: 4,
    title: 'PRO 4K XL',
    imageUrl:
      require("../images/asiga/asiga-pro-4k-xl-front.jpg"),
    description:"Standing tall with 400mm of Z-travel.​"
  },
  {
    id: 5,
    title: 'Asiga DentaMODEL',
    imageUrl:
      require("../images/asiga/Asiga-DentaMODEL-web-1.jpg"),
    description:"For accurate and precise dental models.​"
  },
  {
    id: 6,
    title: 'Asiga DentaSTUDY',
    imageUrl:
    require("../images/asiga/Asiga-DentaSTUDY-web-1.jpg"),
    description:"Vibrant white dental model material for diagnostic and study models.​"
  },
  {
    id: 7,
    title: 'Asiga DentaFORM',
    imageUrl:
      require("../images/asiga/Asiga-DentaFORM-web-1.jpg"),
    description:"Anti-stick model material for vacuum/pressure thermoforming.​"
  },
  {
    id: 8,
    title: 'Asiga DentaGUM',
    imageUrl:
    require("../images/asiga/Asiga-DentaGUM-web-1.jpg"),
    description:"Flexible material for the fabrication of Gingiva masks.​"
  },
  {
    id: 9,
    title: 'Asiga DentaCAST',
    imageUrl:
      require("../images/asiga/Asiga-DentaCAST-web-1.jpg"),
    description:"Direct investment casting and pressing material.​"
  },
  {
    id: 10,
    title: 'Asiga DentaGUIDE',
    imageUrl:
    require("../images/asiga/Asiga-DentaGUIDE-web-1.jpg"),
    description:"Biocompatible material for producing surgical guides.​"
  },
  {
    id: 11,
    title: 'Asiga DentaTRY',
    imageUrl:
      require("../images/asiga/Asiga-DentaTRY-web.jpg"),
    description:"Biocompatible material for Try-In Dentures.​"
  },
  {
    id: 12,
    title: 'Asiga DentaTRAY',
    imageUrl:
    require("../images/asiga/Asiga-DentaTRAY-web-1.jpg"),
    description:"Biocompatible material for Custom Impression Trays.​"
  },
  {
    id: 13,
    title: 'Asiga DentaIBT',
    imageUrl:
      require("../images/asiga/Asiga-DentaIBT-web.jpg"),
    description:"Biocompatible material for producing Indirect Bonding Trays.​"
  },
  {
    id: 14,
    title: 'Asiga DentaBASE',
    imageUrl:
    require("../images/asiga/Asiga-DentaBASE-web.jpg"),
    description:"Biocompatible material for producing Denture Bases.​"
  },
  {
    id: 15,
    title: 'Asiga DentaTOOTH',
    imageUrl:
    require("../images/asiga/Asiga-DentaTOOTH-web.jpg"),
    description:"Biocompatible material for producing Denture Teeth.​"
  },
]

export default function Asiga() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">MEDIT</h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
          Start Your Magical Journey into Digital Dentistry with the MEDIT Family.</p>
        </div>
        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {posts.map((post) => (
            <article key={post.id} className="flex flex-col items-start justify-between">
              <div className="relative w-full">
                <img
                  src={post.imageUrl}
                  alt=""
                  className="aspect-[9/16] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[1/2] lg:aspect-[2/3]"
                />
                <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
              </div>
              <div className="max-w-xl">
                <div className="mt-8 flex items-center gap-x-4 text-xs">
                  
                </div>
                <div className="group relative">
                  <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                    <a href={post.href}>
                      <span className="absolute inset-0" />
                      {post.title}
                    </a>
                  </h3>
                  <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">{post.description}</p>
                </div>
              </div>
            </article>
          ))}
        </div>
      </div>
    </div>
  )
}
