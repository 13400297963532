const posts = [
  {
    id: 1,imageUrl:
      require("../images/milling/Picture3.png"),
  },
  {
    id: 2,
    imageUrl:
      require("../images/milling/Picture4.jpg"),
  },
  {
    id: 3,
    imageUrl:
      require("../images/milling/Picture5.jpg"),
  },
  {
    id: 4,
    imageUrl:
      require("../images/milling/Picture6.png"),
  },
  {
    id: 4,
    imageUrl:
      require("../images/milling/Picture7.png"),
  },
  {
    id: 6,
    imageUrl:
      require("../images/milling/Picture8.png"),
  },
]

export default function Milling() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">MILLING SERVICE</h2>
        </div>
        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {posts.map((post) => (
            <article key={post.id} className="flex flex-col items-start justify-between">
              <div className="relative w-full">
                <img
                  src={post.imageUrl}
                  alt=""
                  className="aspect-[20/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[2/1]"
                />
                <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
              </div>
            </article>
          ))}
        </div>
      </div>
    </div>
  )
}
